/* src/styles/Header.css */
.main-header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left .logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  padding: none;
}

.header-center {
  font-size: larger;
  display: flex;
  gap: 20px;
}

.header-center button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.header-center button:hover {
  color: #007bff;
}

.header-right {
  display: flex;
  gap: 15px;
}

.icon-button {
  position: relative;
  color: #333;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #007bff;
}

.badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #f44336;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .main-header {
    flex-direction: column;
    padding: 15px;
  }

  .header-center {
    margin: 15px 0;
  }

  .header-center button {
    font-size: 14px;
  }
}