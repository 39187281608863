/* src/styles/ProductList.css */
.product-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.search-input,
.category-select,
.sort-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-input {
  flex-grow: 1;
  min-width: 200px;
}

.category-select,
.sort-select {
  min-width: 150px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(252, 249, 249, 0.1);
}

.product-image-container {
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-card:hover .product-image {
  transform: scale(1.05);
}

.favorite-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(239, 236, 236, 0.7);
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.favorite-button:hover {
  background: rgba(255, 255, 255, 0.9);
}

.favorite-button.favorited svg {
  fill: red;
  stroke: red;
}

.product-info {
  padding: 15px;
}

.product-name {
  font-size: 1.1em;
  margin: 0 0 5px 0;
  font-weight: bold;
}

.product-category {
  font-size: 0.9em;
  color: #666;
  margin: 0 0 5px 0;
}

.product-price {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0 0 5px 0;
  color: #4CAF50;
}

.product-colors {
  font-size: 0.8em;
  color: #666;
  margin: 0;
}

.favorites-section {
  margin-top: 40px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.favorites-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.favorite-item {
  text-align: center;
  cursor: pointer;
}

.favorite-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.favorite-item p {
  margin-top: 5px;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
  
  .search-input,
  .category-select,
  .sort-select {
    width: 100%;
  }
}