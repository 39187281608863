/* src/styles/ProductDetail.css */
.product-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.product-detail {
  display: flex;
  gap: 40px;
}

.product-image-gallery {
  flex: 1;
  max-width: 50%;
}

.main-image-container {
  position: relative;
  margin-bottom: 10px;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.favorite-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(241, 236, 236, 0.5);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.favorite-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.thumbnail-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.thumbnail.active,
.thumbnail:hover {
  opacity: 1;
}

.product-info {
  flex: 1;
}

.product-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.price {
  font-size: 20px;
  font-weight: bold;
  color: #4CAF50;
  margin-bottom: 15px;
}

.product-info p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.product-options {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.product-options select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.cart-actions {
  margin-bottom: 20px;
}

.add-to-cart-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #45a049;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.quantity-control button {
  padding: 5px 10px;
  font-size: 18px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  cursor: pointer;
}

.quantity-control span {
  font-size: 18px;
}

.product-features {
  margin-top: 30px;
}

.product-features h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.product-features ul {
  list-style-type: disc;
  padding-left: 20px;
}

.product-features li {
  margin-bottom: 5px;
}

.reviews-section {
  margin-top: 30px;
}

.reviews-section h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.review {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.review-rating {
  color: #ffc107;
}

.review-comment {
  line-height: 1.6;
}

@media (max-width: 768px) {
  .product-detail {
    flex-direction: column;
  }

  .product-image-gallery {
    max-width: 100%;
  }

  .product-options {
    flex-direction: column;
  }
}