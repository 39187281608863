/* src/styles/Favorites.css */
.favorites {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .favorites h2 {
    margin-bottom: 20px;
  }
  
  .favorites-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .favorite-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .favorite-item a {
    text-decoration: none;
    color: inherit;
  }
  
  .favorite-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .favorite-item h3 {
    padding: 10px;
    margin: 0;
    font-size: 1em;
  }
  
  .favorite-item p {
    padding: 0 10px 10px;
    margin: 0;
    color: #4CAF50;
    font-weight: bold;
  }
  
  .remove-favorite {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .remove-favorite:hover {
    background-color: #ff4444;
    color: white;
  }