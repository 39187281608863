/* src/styles/Checkout.css */
.checkout {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .checkout form {
    display: flex;
    flex-direction: column;
  }
  
  .checkout input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .checkout input.error {
    border-color: #ff0000;
  }
  
  .error-message {
    color: #ff0000;
    font-size: 0.8em;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  
  .checkout button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .checkout button:hover {
    background-color: #45a049;
  }
  
  .checkout ul {
    list-style-type: none;
    padding: 0;
  }
  
  .checkout li {
    margin-bottom: 5px;
  }
  
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .checkout-header {
    margin-bottom: 20px;
  }
  
  .contact-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16px;
  }
  
  .contact-button:hover {
    background-color: #85cba2;
  }