/* src/styles/Contact.css */
.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .contact-container h1 {
    text-align: center;
    margin-bottom: 40px;
    color: #333;
  }
  
  .contact-content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  
  .contact-info, .contact-form {
    flex: 1;
  }
  
  .contact-info h2, .contact-form h2 {
    margin-bottom: 20px;
    color: #444;
  }
  
  .contact-details {
    margin-top: 30px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .contact-icon {
    margin-right: 15px;
    color: #4CAF50;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form textarea {
    height: 150px;
  }
  
  .submit-btn {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
    }
  }

  .success-message,
.error-message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #45a049;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: rgb(119, 189, 135)049;
}