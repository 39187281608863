/* src/styles/About.css */
.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .about-hero {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .about-hero h1 {
    font-size: 3em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-hero p {
    font-size: 1.2em;
    color: #666;
  }
  
  .about-intro, .about-features, .about-commitment, .about-cta {
    margin-bottom: 60px;
  }
  
  h2 {
    font-size: 2em;
    color: #444;
    margin-bottom: 20px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
  
  .feature-item {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: transform 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-5px);
  }
  
  .feature-icon {
    font-size: 2em;
    color: #4CAF50;
    margin-bottom: 15px;
  }
  
  .feature-item h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .feature-item p {
    color: #666;
  }
  
  .about-commitment p, .about-cta p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 1.1em;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .about-hero h1 {
      font-size: 2.5em;
    }
  
    .features-grid {
      grid-template-columns: 1fr;
    }
  }